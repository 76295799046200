.sponsors {
  height: max-content;
  background-color: var(--color-brand-light-teal);
  flex-direction: column;
  align-items: center;
  gap: var(--block-l);
  padding-top: var(--block-m);
  padding-bottom: var(--block-m);
}

.sponsors p {
  width: max-content;
}

.container {
  width: 100%;
  height: max-content;
  padding: 4rem 0;
  display: flex;
  background: var(--color-brand-teal);
}

@media only screen and (max-width: 750px) {
  .container {
    padding: 3rem 0;
  }
}

.container p,
.container a {
  font-size: var(--font-size-details) !important;
  color: var(--semantic-color-background-primary) !important;
}

.content {
  display: flex;
  flex-direction: column;
  background: var(--color-brand-dar) !important;
  padding-top: 0;
  padding-bottom: 0;
  gap: 32px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 64px;
}

@media only screen and (max-width: 1000px) {
  .info {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.socials {
  display: flex;
  gap: 16px;
}

.logos {
  display: flex;
  column-gap: var(--block-m);
  row-gap: var(--block-s);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.logoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.socials {
  display: flex;
  gap: 16px;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 48px;
  flex-shrink: 0;
}

.details .grid {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 24px;
  row-gap: 4px;
}

.address {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.privacy {
  justify-self: flex-end;
  width: max-content;
}

.description {
  flex-shrink: 1;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nav a {
  white-space: nowrap;
  cursor: pointer;
  line-height: var(--line-height-body);
}
